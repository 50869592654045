/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@corteva-research/ngx-components-core/assets/fonts/fonts.scss';


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset !important;
}

label,
td,
th {
  font-size: 14px;
}

.layout-horizontal .header-content {
  --header-content-height: calc(var(--theme-grid-base) * 10);
  box-sizing: border-box;
  flex-direction: row;
  height: --header-content-height;
  max-height: --header-content-height;
  background: linear-gradient(270deg, hsl(0deg 0% 0% / 0%) 2%, rgb(0, 114, 206, 0.3) 100%) !important;

}

.layout-horizontal .logo svg {
  height: 0px !important;
  width: auto;
}

blockquote,
ol,
p,
pre,
table,
ul {
  margin-top: 0;
  margin-bottom: unset !important; //1.5rem;
}
/* override css to fix submenu full width issue*/
.nav-group-content-wrapper {
    left: unset !important;
    right: unset !important;
}

/* set z index 10000 to fix growl message popup overlay issue*/
.ui-growl {
    z-index:10000 !important;
 }

 .duplicateItem{
    padding-top: 5px !important;
    display: contents;
}

.alert{
    padding:0.5em, 1.25em !important
}

.ui-fieldset, .ui-fieldset .ui-fieldset-legend{
    width:auto !important;
}

.required:after {
    content:" *";
    color: red;
  }

  .ui-table .ui-table-tbody tr td {
    padding: .25em .5em !important;
  }
  .ui-table .ui-table-thead  tr  th{
    padding: 5px !important;
  }

  .ui-table .ui-table-caption {
    padding: 4px 5px !important;
  }

  // To highlight the data view row on hover
.hover .resShadow:hover {
  color: #1a2326;
  text-decoration: none;
  border:1px solid #2b85e7;
  border-radius: 4px;
  box-shadow: 4px 8px 8px rgba(1,67,163,.24), 0 0 8px rgba(1,67,163,.12), 0 6px 18px rgba(43,133,231,.12);
  transform: translateY(-2px);
  cursor: pointer;

}


.form-label-header {
  font-size: 14px;
  font-weight: bold;
}

.form-label-content {
  font-size: 14px;
}

.form-panel-header {
  font-size: 16px;
  font-weight: bold;
}

.form-panel-subheader {
  font-size: 15px;
  font-weight: bold;
}

.form-panel-header-content {
  font-size: 16px;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token{
  margin-bottom: 0.229em;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active{
  background-color: #30333559;
}

.mandate {
  color: red;
}

.ui-inputgroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.ui-inputgroup-label {
  border-color: #DADADA !important;
  background-color: #DADADA !important;
  color: #474f46 !important;
  height: 25px !important;
  padding: unset !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  font-size: 11px !important;
}

.ui-filter-content {
  padding-left: 0px !important;
  background-color: #f5f5f5;
  background-clip: content-box;
}

ui-select-dropdown {
  height: 25px;
  font-size: 11px;
  border-color: #DADADA;
  padding: unset !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  border-radius: 3px !important;
  margin-right: 3px;
}

.p-datatable .p-datatable-tbody tr td {
  padding: .25em .5em !important;
  vertical-align: middle;
}
.p-datatable  .p-datatable-thead  tr  th{
  padding: 5px !important;
}
.p-datatable  .p-datatable-thead  tr  th input{
  height: 30px;
}

.p-datatable .p-datatable-header {
  padding: 0px 0px !important;
  background-color: white !important;
}

h2 {
  padding-bottom: 10px;
}

.pi-exclamation-triangle,
.fa-exclamation-triangle{
 color: #D32F2F;
}

.header-container {
  z-index: auto !important;
  }

